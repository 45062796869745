import React from 'react'
import styles from './AboutMe.module.scss'
import { motion } from "framer-motion"
import image from '../../image/AboutMe/img_2.jpg'

const imageAnimation = {
    hidden: {
        scale: 0,
        opacity: 0,
    },

    visible: {
        scale: 1,
        opacity: 1,
        transition: { duration: .7 }
    }
}

const textAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .5 }
    }
}

const AboutMe = ({isLicenseOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
                <motion.img className={styles.image} src={image} alt='человек' variants={imageAnimation}/>
                <div className={styles.info}>
                    <motion.h2 
                        className={styles.title} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        
                        >О НАШЕЙ КОМПАНИИ</motion.h2>
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Компания 'БАСэкотранс' предоставляет услуги по вывозу строительного мусора с привлечением современной спецтехники в Москве и Московской области. Помимо вывозу строительного мусора, наша компания занимается транспортировкой других видов отходов, мы готовы вывезти производственные отходы, песок, снег, смешанный и бытовой мусор.
                        </motion.p>
                        <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Работы выполняются согласно законодательству и <span onClick={isLicenseOpen}> действующей лицензии</span>, с соблюдением порядка утилизации. За один раз может быть вывезено от 3 до 20 тонн груза в контейнерах различных объемов. Осуществляем оперативную подачу автомобиля во все города Московской области, есть пропуск в центр Москвы и МКАД.</motion.p>    
                    <motion.p 
                        className={styles.text} 
                        variants={textAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.001, once: true}}
                        >Работаем как с юридическими, так и частными лицами 24 часа в сутки, без перерывов и выходных. При необходимости можем прислать машину в день заказа – располагаем обширным автопарком современных самосвалов различной вместимости. Cотрудники 'БАСэкотранс' делают все возможное, чтобы обеспечить комфортное партнерство с каждым клиентом и завоевать его благодарность и доверие.</motion.p>
                </div>
            </div>   
    </motion.section>
    )
}

export default AboutMe