import React from 'react'
import styles from './Scheme.module.scss'
import { motion } from "framer-motion"
import image_1 from '../../image/Scheme/image_1.svg'

const Scheme = () => {

    const titleAnimation = {
        hidden: {
            y: 200,
            opacity: 0,
        },
    
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .5 }
        }
    }
    
    const cardAnimation = {
        hidden: {
            y: 100,
            opacity: 0,
        },
        visible: {
            y: 0,
            opacity: 1,
            transition: { duration: .4}
        } 
    }

    return (
        <motion.section 
            className={styles.main}
            id='Deadlines' 
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.05, once: true}}
        >
            <div className={styles.container}>
            <motion.h2 className={styles.title} variants={titleAnimation}>Как мы работаем с&#160;клиентами?</motion.h2>
            <motion.ul className={styles.list}>
                <motion.li 
                    className={styles.item}
                    variants={cardAnimation}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>01</p>
                            </div>
                            <h3 className={styles.item_title}>Заявка</h3>
                            <p className={styles.item_text}>Вы оставляете электронную заявку на сайте, электронной почте, мессенджере или звоните нам по телефону.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </motion.li>
                <motion.li 
                    className={styles.item}
                    variants={cardAnimation}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>02</p>
                            </div>
                            <h3 className={styles.item_title}>Консультация</h3>
                            <p className={styles.item_text}>Уточняем особенности вашего заказа, обьем поставки или груза, возможность подъезда, удобные дату и время и производим расчет стоимости.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </motion.li>
                <motion.li 
                    className={styles.item}
                    variants={cardAnimation}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.item_container}>
                        <div className={styles.box} >
                        <div className={styles.item_box}>
                            <img className={styles.icon} src={image_1} alt='этап'/>
                            <p className={styles.number}>03</p>
                        </div>
                        <h3 className={styles.item_title}>Выезд</h3>
                        <p className={styles.item_text}>В назначенные дату и время осуществляется выезд необходимой техники по заявленному адресу, разгрузка доставленных материалов или погрузка строительного мусора, ненужного грунта или снега.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </motion.li>
                <motion.li 
                    className={styles.item}
                    variants={cardAnimation}
                    initial='hidden'
                    whileInView='visible'
                    viewport={{amount: 0.05, once: true}}
                    >
                    <div className={styles.item_container_red}>
                        <div className={styles.box_red} >
                            <div className={styles.item_box}>
                                <img className={styles.icon} src={image_1} alt='этап'/>
                                <p className={styles.number}>04</p>
                            </div>
                            <h3 className={styles.item_title}>Оплата</h3>
                            <p className={styles.item_text}>Вы производите оплату поставленных материалов или предоставленных услуг любым удобным для вас способом.</p>
                        </div>
                    </div>
                    <div className={styles.item_background}></div>
                </motion.li>         
            </motion.ul>
            </div>   
        </motion.section>
        
        
    )
}

export default Scheme
