import React from 'react'
import styles from './Footer.module.scss'
import logo from '../../image/Header/logo.png'

const Footer = ({isOpen}) => {

    return (
        <footer className={styles.main}>
            <div className={styles.container}>
                <div className={styles.box}>
                    <img className={styles.logo} src={logo} alt = 'логотип'/>
                </div>
                <p className={styles.link_politics} tupe= 'button' onClick={isOpen}>Политика конфиденциальности</p>
                <div className={styles.box}>
                    <p className={styles.text}>&#169; 2024 Все права защищены.</p>
                    <a className={styles.link_developer} target='_blank' rel='noopener noreferrer' href='https://tihonov-studio.ru/' aria-label='сайт тихонова дмитрия'>
                        <p className={styles.text}>Разработано:<span>  tihonov-studio.ru</span></p>
                    </a>
                </div>
            </div>
        </footer>
    )
}

export default Footer