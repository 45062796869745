import React from 'react'
import styles from './Advantages.module.scss'
import { motion } from "framer-motion"

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .4}
    } 
}


const Advantages = ({isOpen}) => {

    return (
        <motion.section 
        className={styles.main}
        id='gruppy' 
        initial='hidden'
        whileInView='visible'
        viewport={{amount: 0.05, once: true}}
        >
        <div className={styles.container}>
        <motion.h2 className={styles.title} variants={titleAnimation}>ПОЧЕМУ ИМЕННО МЫ?</motion.h2>
        <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>01</p>
                            <p className={styles.item_title}>Собственный автопарк</p>
                        </div>
                        <p className={styles.item_text}>Наша компания располагает собственным автопарком современных самосвалов и специальной техники. Устанавливаем на площадках свои контейнеры для производственных отходов.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>02</p>
                            <p className={styles.item_title}>Работаем 24/7</p>
                        </div>
                        <p className={styles.item_text}>Мы работаем без перерывов и выходных. Это значит, что вы можете позвонить круглосуточно, и специалисты компании  приедут в любую точку Москвы и Московской области.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>03</p>
                            <p className={styles.item_title}>Наличие лицензии</p>
                        </div>
                        <p className={styles.item_text}>Все работы работы по сбору и транспортировке выполняются согласно законодательству и действующей лицензии, с соблюдением порядка утилизации.</p>
                    </motion.li>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.05, once: true}}
                        >
                        <div className={styles.item_box} >
                            <p className={styles.item_number}>04</p>
                            <p className={styles.item_title}>Удобный расчет</p>
                        </div>
                        <p className={styles.item_text}>Работаем за наличные и безналичный расчет, НДС. Заключаем договора с компаниями на разовые и постоянные услуги.</p>
                    </motion.li>
                </motion.ul>
        </div>   
    </motion.section>
    )
}

export default Advantages