import React from 'react'
import styles from './Main.module.scss'
import InitialPage from '../../components/initialPage/InitialPage'
import AboutMe from '../../components/AboutMe/AboutMe'
import Questions from '../../components/Questions/Questions'
import Advantages from '../../components/Advantages/Advantages'
import Callback from '../../components/Callback/Callback'
import Map from '../../components/Map/Map'
import Feedback from '../../components/Feedback/Feedback'
import Scheme from '../../components/Scheme/Scheme'
import Services from '../../components/Services/Services'
import Practical from '../../components/Practical/Practical'
import BasicServices from '../../components/BasicServices/BasicServices'

const Main = ({isOpen, popupOpen, isLicenseOpen}) => {

    return (
        <section className={styles.main}>
            <InitialPage
                isOpen={isOpen}
            />
            <AboutMe
                isLicenseOpen={isLicenseOpen}
            />
            <BasicServices
                isOpen={isOpen}
            />
            <Services
                isOpen={isOpen}
            />
            <Advantages
                isOpen={isOpen}
            />
            <Feedback/>
            <Practical/>
            <Scheme/>
            <Questions/>
            <Callback
                popupOpen={popupOpen}
            />
            <Map
                isLicenseOpen={isLicenseOpen}
            />
        </section>
    )
}

export default Main