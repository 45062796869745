import React from 'react'
import styles from './InitialPage.module.scss'
import wp from '../../image/InitialPage/touch.svg'


const InitialPage = ({isOpen}) => {
    return (
        <section className={styles.main}>
            <div className={styles.overlay}>
                <div className={styles.container}>
                    <h1 className={styles.title}>Вывоз строительного мусора</h1>
                    <ul className={styles.list}>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Вывозим <span>любой строительный мусор, грунт, снег </span></p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Перевозим любые сыпучие строительные материалы</p>
                        </li>
                        <li className={styles.item}>
                            <p className={styles.check}>&#10003;</p>
                            <p className={styles.item_text}>Поставляем <span>щебень, грунт, песок, асфальтовую крошку</span></p>
                        </li>
                    </ul>
                    <div className={styles.box_button}>
                        <button className={styles.button} type='button' onClick={isOpen}>получить консультацию <span className={styles.glare}></span> <img src={wp} alt="Whatsapp"/></button>
                    </div>
                </div>
            </div> 
        </section>
    )
}

export default InitialPage