import React from 'react'
import styles from './BasicServices.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/BasicServices/img_1.webp'
import icon_2 from '../../image/BasicServices/img_2.webp'
import icon_3 from '../../image/BasicServices/img_3.webp'

const titleAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}

const BasicServices = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>услуги по вывозу строительного мусора</motion.h2>
                    <motion.ul className={styles.list}>
                    <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_1} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Контейнер 8 м³</h3>
                                    <p className={styles.item_price}><span>от 9 000 ₽</span></p>
                                </div>
                                <button className={styles.button} type='button' onClick={isOpen} >ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_2} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Контейнер 20 м³</h3>
                                    <p className={styles.item_price}><span>от 20 000 ₽</span></p>
                                </div>
                                <button className={styles.button} type='button' onClick={isOpen} >ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_3} alt='илосос'/>
                            <div className={styles.box_info}>
                                <div className={styles.info}>
                                    <h3 className={styles.item_title}>Контейнер 27 м³</h3>
                                    <p className={styles.item_price}><span>от 27 000 ₽</span></p>
                                </div>
                                <button className={styles.button}  type='button' onClick={isOpen}>ЗАКАЗАТЬ <span className={styles.glare}></span></button>
                            </div>
                        </motion.li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}

export default BasicServices